<template>
  <v-container fluid>
  <PersonnelTem/>
  </v-container>
</template>

<script>
import PersonnelTem from '../../components/personnelTem.vue';

export default {
    name: 'HRvecPersonnelTemporary',
    data() {
        return {};
    },
    mounted() {
    },
    methods: {},
    components: { PersonnelTem }
};
</script>

<style lang="scss" scoped>

</style>