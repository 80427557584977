<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="'ข้าราชการครูและบุคลากรทางการศึกษา : ' + userinfos.agencys"
        class="px-5 py-3"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          v-on:keyup.enter="OnEnter()"
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          :headers="headers"
          :items="personnel_temporarys"
          class="elevation-1"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.brith_day="{ item }">
            {{
              item.brith_day + "/" + item.brith_month + "/" + item.brith_year
            }}
          </template>
          <template v-slot:item.appoin_day="{ item }">
            {{
              item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
            }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="personnel_temporaryEdit(item.id_card)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      personnel_temporarys: [],
      search: "",
      pagination: {},
      headers: [
        { text: "วิทยาลัย", align: "center", value: "college_name" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "สกุล", align: "left", value: "last_name" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "วิทยฐานะ", align: "center", value: "rang_name" },
        { text: "วันที่วิทยฐานะ", align: "center", value: "date_rang_level" },
        { text: "ระดับ", align: "center", value: "rang_level" },
        { text: "สาขาวิชา", align: "center", value: "branch_name_th" },

        { text: "วันเดือนปีเกิด", align: "center", value: "brith_day" },
        { text: "วันเดือนปีบรรจุ", align: "center", value: "appoin_day" },
        { text: "คำสั่งที่", align: "center", value: "order_app_now" },
        {
          text: "วันที่ปฏิบัติหน้าที่ ณ สถานศึกษาปัจจุบัน",
          align: "center",
          value: "date_app_now"
        },
        {
          text: "วันที่ปฏิบัติหน้าที่ ในตำแหน่ง",
          align: "center",
          value: "date_app_positonnow"
        },
        { text: "เกษียณ", align: "center", value: "retrire_year" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      userinfos: []
    };
  },

  async mounted() {
    await this.userQuery();
    await this.personnel_temporaryQueryAll();
  },

  /* 
  A Admin
  B College
  C iven
  G ProviceVec
  S Supervisions
  */

  methods: {
    async userQuery() {
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http.post("user.php", {
        ApiKey: this.ApiKey,
        user_ID: userSession.user_ID
      });
      this.userinfos = result.data;
      
    },
    async personnel_temporaryQueryAll() {
      if (this.userinfos.user_status == "S") {
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            region_ID: this.userinfos.region_ID
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
      } else if (this.userinfos.user_status == "C") {
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            collegeVei: this.userinfos.user_code
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
      } else if (this.userinfos.user_status == "G") {
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            province_ID: this.userinfos.province_ID
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
